import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import { path, prop, propOr } from 'ramda'
import Link from '@mui/material/Link'
import { useTranslation } from 'react-i18next'

import { CardHeader } from '~/components/Cards'
import TextLabelLoader from '~/components/TextLabelLoader'
import Tags from '~/components/Tags'
import NotesCard from '~/components/NotesCard'
import CurrencyMoney from '~/components/Money/CurrencyMoney'
import { PAYMENT_TYPES } from '~/constants/paymentTypes'
import TextLoader from '~/components/TextLoader'
import { getFormattedDate, generateCdnUrl } from '~/utils'

import Statuses from './Statuses'
import LineItems from './LineItems'
import SaleOrderCompanyInfo from './SaleOrderCompanyInfo'
import SaleOrderCustomerDetail from './Customer/SaleOrderCustomerDetail'

import InvoiceSummary from '../InvoiceSummary'

function SaleOrderGeneral ({ detail, company, isLoading }) {
  const { t } = useTranslation()
  const lineItems = prop('lineItems', detail)
  const currency = prop('currency', detail)
  const customer = propOr({}, 'customer', detail)
  const deliveryAddress = propOr({}, 'deliveryAddress', detail)
  const billingAddress = propOr({}, 'billingAddress', detail)
  const warehouse = path(['warehouse', 'name'], detail)
  const companyName = path(['company', 'name'], detail)
  const channel = path(['channel', 'name'], detail)
  const deliveryMethod = path(['deliveryMethod', 'name'], detail)
  const paymentType = prop('paymentType', detail)
  const referenceNumber = prop('referenceNumber', detail)
  const trackingNumber = prop('trackingNumber', detail)
  const shippingMethod = path(['shippingMethod', 'name'], detail)
  const tags = propOr([], 'tags', detail)
  const customerNotes = prop('customerNotes', detail)
  const shippingFee = propOr(0, 'shippingFee', detail)
  const extraFees = propOr(0, 'extraFees', detail)
  const totalPrice = propOr(0, 'totalPrice', detail)
  const awbUrl = prop('awbUrl', detail)
  const dueDate = prop('dueDate', detail)
  const commercialInvoiceUrl = prop('commercialInvoiceUrl', detail)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Statuses isLoading={isLoading} detail={detail} />
        </Box>
        <Box>
          <Card>
            <CardHeader title="Customer" />
            <Divider />
            <CardContent>
              <SaleOrderCustomerDetail
                customer={customer}
                deliveryAddress={deliveryAddress}
                billingAddress={billingAddress}
              />
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <Grid container={true} spacing={3}>
          {(awbUrl || commercialInvoiceUrl) && (
            <Grid item={true} lg={12}>
              <Card style={{ height: '100%' }}>
                <CardHeader title="Shipment" />
                <Divider />
                <CardContent style={{ height: '100%' }}>
                  <Grid container={true} spacing={3}>
                    {awbUrl && (
                      <Grid item={true} xs={12}>
                        <Link
                          href={generateCdnUrl(awbUrl)}
                          rel="noopener noreferrer"
                          underline="always"
                          target="_blank"
                        >
                          <TextLoader>{t('AWB Url')}</TextLoader>
                        </Link>
                      </Grid>
                    )}
                    {commercialInvoiceUrl && (
                      <Grid item={true} xs={12}>
                        <Link
                          href={generateCdnUrl(commercialInvoiceUrl)}
                          rel="noopener noreferrer"
                          underline="always"
                          target="_blank"
                        >
                          <TextLoader>{t('Commercial Invoice Url')}</TextLoader>
                        </Link>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          <Grid item={true} lg={12}>
            <Card style={{ height: '100%' }}>
              <CardHeader title="Details" />
              <Divider />
              <CardContent style={{ height: '100%' }}>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Owner">
                      <SaleOrderCompanyInfo owner={company} />
                    </TextLabelLoader>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Warehouse">
                      {warehouse}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Company">
                      {companyName}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Delivery method">
                      {deliveryMethod}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Channel">
                      {channel}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Currency">
                      {currency}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Payment type">
                      {PAYMENT_TYPES[paymentType]}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Reference number">
                      {referenceNumber}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Tags">
                      {tags.length > 0 ? <Tags items={tags} /> : null}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Shipping fee">
                      <CurrencyMoney currency={currency} value={shippingFee} />
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Extra fees">
                      <CurrencyMoney currency={currency} value={extraFees} />
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Promise date">
                      {getFormattedDate(dueDate)}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Tracking number">
                      {trackingNumber}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Shipping method">
                      {shippingMethod}
                    </TextLabelLoader>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item={true} xs={12}>
        <Card>
          <CardHeader title="Line items" />
          <Divider />
          <LineItems currency={currency} lineItems={lineItems} isLoading={isLoading} />
        </Card>
      </Grid>
      <Grid item={true} xs={12} lg={6}>
        <Card>
          <CardHeader title="Invoice summary" />
          <Divider />
          <InvoiceSummary
            extraFees={extraFees}
            shippingFee={shippingFee}
            currency={currency}
            lineItems={lineItems}
            totalPrice={totalPrice}
            updated={false}
          />
        </Card>
      </Grid>
      <Grid item={true} xs={12} lg={6}>
        <Card style={{ height: '100%' }}>
          <NotesCard title="Customer notes" notes={customerNotes} />
        </Card>
      </Grid>
    </Grid>
  )
}

SaleOrderGeneral.propTypes = {
  detail: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  company: PropTypes.object.isRequired
}

export default SaleOrderGeneral
