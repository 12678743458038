import React, { useState } from 'react'
import { Box, styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { isDev, useDeepCompareEffect, useRequest } from 'storfox-api-hooks'
import { prop, path } from 'ramda'
import { useAllSearchParams } from 'storfox-route-hooks'
import { generatePath, useNavigate } from 'react-router-dom'

import AppContainer from '~/components/AppContainer'
import Logo from '~/components/Logo'
import * as API from '~/constants/api'
import { useMe, useProfile } from '~/components/Profile'
import { useToken } from '~/components/Token'
import * as ROUTES from '~/constants/routes'
import { unescapeBtoa } from '~/utils'
import { OVERVIEW_TABS } from '~/constants/tabs'

import Delivery from '../components/Images/delivery.svg'
import WarehouseImage from '../components/Images/warehouse-image.png'

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '70vw',
  height: '100vh',
  zIndex: 10,
  background: '#FFFFFF',
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  }
}))

const BoxImageContainer = styled(Box)(({ theme }) => ({
  width: '30vw',
  height: '100vh',
  zIndex: 9,
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}))

const ImageTopBox = styled(Box)({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  zIndex: 8,
  width: '100%',
  height: '100vh'
})

const ImageTopText = styled(Typography)(({ theme }) => ({
  marginBottom: '24px',
  width: '15vw',
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    fontSize: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px'
  }
}))

const ImageGradientBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  zIndex: 7
})

const GradientBox = styled(Box)({
  width: '100%',
  height: '100vh',
  background: 'rgba(54, 54, 54, 0.95)',
  zIndex: 6
})

const LogoStyled = styled(Logo)({
  width: 160,
  height: 60
})

const LogoBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  padding: '7px',
  display: 'flex',
  justifyContent: 'space-between',
  width: '70vw',
  alignItems: 'center',
  background: '#415C98',
  height: '50.5px',
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  }
}))

const DeliveryStyled = styled(Delivery)({
  width: '25vw'
})

function SignUpContainer () {
  const params = useAllSearchParams()
  const { t } = useTranslation()
  const request = useRequest()
  const navigate = useNavigate()
  const { getMe } = useMe()
  const { token, setToken } = useToken()
  const { setProfile } = useProfile()
  const [signUp, setSignUp] = useState(false)

  useDeepCompareEffect(() => {
    if (!signUp) {
      if (token) {
        navigate(ROUTES.DASHBOARD_PATH)
      }
    }
  }, [token, signUp])

  const code = prop('code', params)

  useDeepCompareEffect(() => {
    if (code) {
      setSignUp(true)
      request.post(API.AGGRAK_CALLBACK, { code, redirectUri: isDev ? "https://aggrak.storfox.com/oauth2/aggrakid/callback/" : "https://wms.aggrak.com/oauth2/aggrakid/callback/" }).then((res) => {
        const resToken = path(['data', 'token'], res)
        setToken(resToken)
        getMe()
          .then((response) => {
            const companyType = path(['result', 'company', 'type'], response)
            if (companyType === 'empty') {
              setToken(null)
              navigate(`${ROUTES.SIGN_UP}?profile=${unescapeBtoa(prop('result', response))}`)
            } else {
              setProfile(prop('result', response))
              const redirectTo = generatePath(ROUTES.OVERVIEW_PATH, { tab: OVERVIEW_TABS.FULFILLMENT })
              navigate(redirectTo)
            }
          })
      })
    }
  }, [getMe, setProfile, setToken, request, code, navigate])

  return (
    <AppContainer title="Sign Up">
      <Box sx={{ display: 'flex' }}>
        <BoxContainer>
          <LogoBox>
            <LogoStyled />
          </LogoBox>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '24px'
            }}
          >
            <div className="loader" />
          </Box>
        </BoxContainer>
        <BoxImageContainer>
          <ImageTopBox>
            <ImageTopText color="white" variant="h2">{t('Delivered Happiness, Every Step of the Way')}</ImageTopText>
            <DeliveryStyled />
          </ImageTopBox>
          <ImageGradientBox>
            <GradientBox />
            <img
              src={WarehouseImage}
              alt="sign-in-image"
              style={{ height: '100vh', position: 'absolute', zIndex: 5, width: '30vw', objectFit: 'cover' }}
            />
          </ImageGradientBox>
        </BoxImageContainer>
      </Box>
    </AppContainer>
  )
}

export default SignUpContainer
