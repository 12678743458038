import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { always, map, path, prop, propOr } from 'ramda'
import { generatePath } from 'react-router-dom'
import { useAllSearchParams } from 'storfox-route-hooks'

import PageTitle from '~/components/PageTitle'
import { Button, MoreListButton } from '~/components/Buttons'
import TableDateFormat from '~/components/TableDateFormat'
import Tags from '~/components/Tags'
import TableNotes from '~/components/TableNotes'
import Container, { Content, Header } from '~/components/Container'
import TextOverflow from '~/components/TextOverflow'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { RECEIVE_TABS } from '~/constants/tabs'
import TableLink from '~/components/Link'
import { useTableSelects } from '~/components/TableValues/hooks'

import ReceiveOrderProgress from './ReceiveOrderProgress'
import ReceiveStatus from './ReceiveStatus'

import { PROGRESS, PURCHASE_ORDER, RETURN, STOCK } from '../../constants'

const getDetailPath = (guid, type) => {
  const guidParams = { guid, tab: RECEIVE_TABS.GENERAL }

  const url = {
    [STOCK]: () => generatePath(ROUTES.RECEIVE_TRANSFER_DETAIL_PATH, guidParams),
    [PURCHASE_ORDER]: () => generatePath(ROUTES.RECEIVE_PURCHASE_ORDER_DETAIL_PATH, guidParams),
    [RETURN]: () => generatePath(ROUTES.RECEIVE_RETURN_DETAIL_PATH, guidParams)
  }

  const generateUrl = propOr(always(null), type, url)
  return generateUrl()
}

export const RECEIVE_COLUMNS = [
  {
    width: 100,
    headerName: 'ID #',
    field: 'number',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        {value}
      </TableLink>
    )
  },
  {
    width: 200,
    headerName: 'Received/Expected',
    field: 'receivedStatus',
    valueFormatter: ({ value }) => `${value.received}/${value.ordered}`,
    renderCell: ({ value }) => (
      <ReceiveOrderProgress
        ordered={value.ordered}
        received={value.received}
      />
    )
  },
  {
    width: 200,
    headerName: 'Receiving warehouse',
    field: 'warehouse'
  },
  {
    width: 200,
    headerName: 'Client company',
    field: 'client'
  },
  {
    width: 200,
    headerName: 'From',
    field: 'supplier',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 150,
    headerName: 'Status',
    field: 'orderStatus',
    renderCell: ({ value }) => (
      <ReceiveStatus value={value} />
    )
  },
  {
    width: 125,
    headerName: 'Notes',
    field: 'notes',
    renderCell: ({ value }) => (
      <TableNotes>{value}</TableNotes>
    )
  },
  {
    width: 300,
    headerName: 'Tags',
    field: 'tags',
    renderCell: ({ value }) => (
      <Tags items={value} />
    )
  },
  {
    width: 200,
    headerName: 'Expected date',
    field: 'expectedDate',
    renderCell: ({ value }) => (
      <TableDateFormat date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat date={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const objectId = prop('objectId', item)
  const objectGuid = prop('objectGuid', item)
  const guid = prop('guid', item)
  const type = prop('objectType', item)
  const number = prop('number', item)
  const client = path(['fromCompany', 'name'], item)
  const supplier = prop('sourceName', item)
  const orderStatus = prop('status', item)
  const products = prop('products', item)
  const ordered = prop('totalQuantity', item)
  const received = prop('received', item)
  const receivedStatus = { received, ordered }
  const warehouse = path(['warehouse', 'name'], item)
  const orderDate = prop('orderDate', item)
  const expectedDate = prop('expectedDate', item)
  const updatedAt = prop('updatedAt', item)
  const tags = propOr([], 'tags', item)
  const supplierReference = prop('supplierReference', item)
  const notes = prop('notes', item)
  const link = getDetailPath(guid, type)

  return {
    id,
    guid,
    objectId,
    client,
    objectGuid,
    type,
    number,
    supplier,
    orderStatus,
    products,
    receivedStatus,
    warehouse,
    orderDate,
    updatedAt,
    supplierReference,
    tags,
    notes,
    link,
    expectedDate
  }
})

function ReceiveTable ({ list, filter, ordering, onListRefetch, onComplete }) {
  const [tableRef, setTableRef] = useState(null)
  const { status } = useAllSearchParams()
  const { selects } = useTableSelects()
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Receiving"
          parentTitle={NAV.STOCK}
          rightButton={
            <>
              {status === PROGRESS && (
                <Button
                  variant="contained"
                  disabled={selects.length === 0}
                  onClick={onComplete}
                >
                  Complete
                </Button>
              )}
            </>
          }
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          pinnedColumns={['number']}
          count={count}
          primaryKey="guid"
          checkboxSelection={true}
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

ReceiveTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default ReceiveTable
