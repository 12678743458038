import React from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { useTranslation } from 'react-i18next'

import { MoreButton } from '~/components/Buttons'

function More (props) {
  const {
    disableAwbGet,
    disableReportGenerate,
    disableCommercialInvoice,
    onAwbGet,
    onReportGenerate,
    onCommercialInvoiceGenerate,
    onOutboundGatePassOpen,
    onRecalculate,
    disableRecalculate,
    disableSetStatus,
    onSetStatusOpen,
    onCancelCarrier,
    cancelDisabled,
    gatepassDisabled,
    onTrackingUrlClick,
    trackingUrlDisabled
  } = props

  const { t } = useTranslation()

  return (
    <MoreButton>
      {onClose => (
        <List>
          <ListItem
            button={true}
            disabled={disableReportGenerate}
            onClick={() =>
              onReportGenerate()
                .then(() => {
                  onClose()
                })
            }
          >
            <ListItemText primary={t('Generate report')} />
          </ListItem>

          <ListItem
            button={true}
            disabled={cancelDisabled}
            onClick={() => {
              onCancelCarrier()
              onClose()
            }}
          >
            <ListItemText primary={t('Cancel carrier')} />
          </ListItem>

          <ListItem
            button={true}
            onClick={() => {
              onOutboundGatePassOpen()
              onClose()
            }}
            disabled={gatepassDisabled}
          >
            <ListItemText primary={t('Generate outbound gate pass')} />
          </ListItem>

          <ListItem
            button={true}
            disabled={disableSetStatus}
            onClick={() => {
              onSetStatusOpen()
              onClose()
            }}
            data-cy="setStatusManually"
          >
            <ListItemText primary={t('Set status manually')} />
          </ListItem>
          <ListItem
            button={true}
            disabled={disableCommercialInvoice}
            onClick={() =>
              onCommercialInvoiceGenerate()
                .then(() => {
                  onClose()
                })
            }
          >
            <ListItemText primary={t('Commercial invoice')} />
          </ListItem>

          <ListItem
            button={true}
            disabled={disableRecalculate}
            onClick={() => {
              onRecalculate()
              onClose()
            }}
          >
            <ListItemText primary={t('Recalculate')} />
          </ListItem>

          <ListItem
            button={true}
            disabled={disableAwbGet}
            onClick={() => onAwbGet()}
          >
            <ListItemText primary={t('AWB')} />
          </ListItem>

          <ListItem
            button={true}
            disabled={trackingUrlDisabled}
            onClick={onTrackingUrlClick}
          >
            <ListItemText primary={t('Tracking url')} />
          </ListItem>
        </List>
      )}
    </MoreButton>
  )
}

More.propTypes = {
  onAwbGet: PropTypes.func.isRequired,
  onReportGenerate: PropTypes.func.isRequired,
  onCommercialInvoiceGenerate: PropTypes.func.isRequired,
  disableReportGenerate: PropTypes.bool.isRequired,
  disableAwbGet: PropTypes.bool.isRequired,
  disableCommercialInvoice: PropTypes.bool.isRequired,
  onOutboundGatePassOpen: PropTypes.func.isRequired,
  onRecalculate: PropTypes.func.isRequired,
  disableRecalculate: PropTypes.bool.isRequired,
  onSetStatusOpen: PropTypes.func,
  onCancelCarrier: PropTypes.func,
  cancelDisabled: PropTypes.bool,
  disableSetStatus: PropTypes.bool,
  gatepassDisabled: PropTypes.bool,
  onTrackingUrlClick: PropTypes.func,
  trackingUrlDisabled: PropTypes.bool
}

export default More
