import React from 'react'
import { useParams } from 'react-router-dom'
import { path, prop, propOr } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import ErrorLink from '~/components/ErrorLink'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import { SHIPMENT_DETAIL_TABS } from '~/constants/tabs'
import { generateCdnUrl } from '~/utils'
import { useNotification } from '~/components/Notification'
import { SHIPMENT_CANCEL_CARRIER_STATUS } from '~/constants/notification-topics'

import {
  useShipmentAwbGet,
  useShipmentContainerUpdate,
  useShipmentDetail,
  useShipmentGenerateCommercialInvoice,
  useShipmentHistory,
  useShipmentOutboundGatePassGenerate,
  useShipmentProcess,
  useShipmentRecalculate,
  useShipmentReportGenerate,
  useShipmentSaveProcess,
  useShipmentSetStatus,
  useShipmentUpdate,
  useShipmentCancelCarrier,
  useShipmentIntegrationList,
  useShipmentSetCourier
} from '../hooks'
import {
  ShipmentInitSerializer,
  ShipmentUpdateContainerSerializer,
  ShipmentGatePassGenerateSerializer,
  ShipmentSetCourierSerializer
} from '../serializers'
import ShipmentDetail from '../components/ShipmentDetail/ShipmentDetail'

function ShipmentDetailContainer () {
  const { guid, tab } = useParams()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const cancelNotification = useNotification(SHIPMENT_CANCEL_CARRIER_STATUS)

  const shipmentDetail = useShipmentDetail(guid)
  const shipmentUpdate = useShipmentUpdate(guid)

  const id = prop('id', shipmentDetail.detail)

  const shipmentProcess = useShipmentProcess(id)
  const shipmentSaveProcess = useShipmentSaveProcess(id)
  const shipmentReportGenerate = useShipmentReportGenerate(id)
  const shipmentGenerateCommercialInvoice = useShipmentGenerateCommercialInvoice(guid)
  const shipmentAwbGet = useShipmentAwbGet(guid)
  const shipmentOutboundGatePassGenerate = useShipmentOutboundGatePassGenerate(guid)
  const shipmentCancelCarrier = useShipmentCancelCarrier(guid)
  const shipmentIntegrationList = useShipmentIntegrationList()
  const shipmentSetCourier = useShipmentSetCourier(guid)

  const historyAutoSend = Boolean(guid && tab === SHIPMENT_DETAIL_TABS.HISTORY)

  const shipmentSetStatus = useShipmentSetStatus(guid)
  const shipmentRecalculate = useShipmentRecalculate(guid)
  const shipmentHistory = useShipmentHistory(guid, historyAutoSend)
  const shipmentContainerUpdate = useShipmentContainerUpdate(guid)

  const handleProcess = () =>
    shipmentProcess.process()
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => shipmentDetail.getDetail())
      .catch(error => snackbar({ message: <ErrorLink error={error} />, type: ALTER_ERROR }))

  const handleUpdateContainer = (guid, values) =>
    shipmentContainerUpdate.update(ShipmentUpdateContainerSerializer(guid, values))
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const handleCancelCarrier = () =>
    shipmentCancelCarrier.cancel()
      .then(() => {
        snackbar({ message: messages.NOTIFICATION_WAIT })
        cancelNotification.setLoading(true)
        cancelNotification.subscribe(guid, payload => {
          cancelNotification.setLoading(false)
          const success = path(['payload', 'success'], payload)
          if (success) {
            snackbar({ message: messages.UPDATE_SUCCESS })
            shipmentDetail.getDetail()
          } else {
            const message = path(['payload', 'message'], payload)
            snackbar({ message, type: ALTER_ERROR })
          }
        })
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const handleReportGenerate = () =>
    shipmentReportGenerate.generate()
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const handleAwbGet = () =>
    shipmentAwbGet.getDetail()
      .then(({ result }) => {
        window.open(generateCdnUrl(prop('url', result)))
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const handleCommercialInvoiceGenerate = () =>
    shipmentGenerateCommercialInvoice.getDetail()
      .then(() => snackbar({ message: messages.GENERATE_SUCCESS }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const handleRecalculate = () =>
    shipmentRecalculate.recalculate()
      .then(() => shipmentDetail.getDetail())
      .then(() => snackbar({ message: messages.RECALCULATE_SUCCESS }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })

        return Promise.reject(error)
      })

  const handleSetStatus = ({ status }) =>
    shipmentSetStatus.set({ status })
      .then(() => shipmentDetail.getDetail())
      .then(() => snackbar({ message: messages.SET_SUCCESS }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const handleOutboundGatePassGenerate = formValues =>
    shipmentOutboundGatePassGenerate.generate(ShipmentGatePassGenerateSerializer({ ...formValues, shipment: { id } }))
      .then(() => snackbar({ message: messages.GENERATE_SUCCESS }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const handleSetCourier = formValues =>
    shipmentSetCourier.create(ShipmentSetCourierSerializer(formValues))
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => shipmentDetail.getDetail())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const title = prop('shipmentNumber', shipmentDetail.detail)
  const pageLoading = shipmentDetail.isLoading || shipmentUpdate.isLoading || shipmentAwbGet.isLoading

  const isLoading = (
    shipmentUpdate.isLoading ||
    shipmentDetail.isLoading ||
    shipmentSaveProcess.isLoading ||
    shipmentReportGenerate.isLoading ||
    shipmentOutboundGatePassGenerate.isLoading
  )

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.SHIPMENTS}
      breadcrumbs={breadcrumbs}
    >
      <ShipmentDetail
        title={title}
        isLoading={isLoading}
        onProcess={handleProcess}
        pageTitleLoading={shipmentDetail.isLoading}
        onUpdateContainer={handleUpdateContainer}
        shipment={ShipmentInitSerializer(shipmentDetail.detail)}
        shipmentHistory={shipmentHistory}
        onCommercialInvoiceGenerate={handleCommercialInvoiceGenerate}
        onReportGenerate={handleReportGenerate}
        onAwbGet={handleAwbGet}
        onRecalculate={handleRecalculate}
        onSetStatus={handleSetStatus}
        onOutboundGatePassGenerate={handleOutboundGatePassGenerate}
        onCancelCarrier={handleCancelCarrier}
        integrationList={propOr([], 'results', shipmentIntegrationList)}
        onCourierUpdate={handleSetCourier}
        setCourierLoading={shipmentSetCourier.isLoading}
      />
    </DashboardLayout>
  )
}

export default ShipmentDetailContainer
