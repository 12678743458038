import React from 'react'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { path, prop } from 'ramda'
import Table from '@mui/material/Table'
import PropTypes from 'prop-types'

import Avatar from '~/components/Avatar/Avatar'
import { Weight } from '~/components/Converters'

function UnitTable ({ units }) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Image</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Quantity</TableCell>
          <TableCell>Unit Number</TableCell>
          <TableCell>Batch Number</TableCell>
          <TableCell>Weight</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {units.map((unit) => {
          const image = path(['variant', 'defaultImage'], unit)
          const name = path(['variant', 'name'], unit)
          const unitNumber = prop('unitNumber', unit)
          const batchNumber = prop('batchNumber', unit)
          const weight = path(['variant', 'weight'], unit)
          const quantity = prop('quantity', unit)

          return (
            <TableRow key={name}>
              <TableCell>
                <Avatar
                  alt={name}
                  src={image}
                />
              </TableCell>
              <TableCell>{name}</TableCell>
              <TableCell>{quantity}</TableCell>
              <TableCell>{unitNumber}</TableCell>
              <TableCell>{batchNumber}</TableCell>
              <TableCell>
                <Weight value={weight} />
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

UnitTable.propTypes = {
  units: PropTypes.array
}

export default UnitTable
